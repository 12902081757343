<template>
  <div
    v-if="articlesTop && articlesTop.length"
    class="page-recipes__interesting"
  >
    <div class="container">
      <h2 class="title">{{ $t("interestingUseful") }}</h2>

      <articles-top-list v-if="articlesTop" :items="articlesTop" />

      <articles-bottom-list v-if="articlesBottom" :items="articlesBottom" />
    </div>
  </div>
</template>

<script>
import { getArticles } from "@/api/news";
export default {
  name: "ArticlesBlock",
  components: {
    ArticlesBottomList: () =>
      import("@/components/articles/ArticlesBottomList.vue"),
    ArticlesTopList: () => import("@/components/articles/ArticlesTopList.vue"),
  },
  data() {
    return {
      articlesTop: null,
      articlesBottom: null,
    };
  },
  created() {
    getArticles().then((articles) => {
      this.articlesTop = articles.data.slice(0, 3);
      this.articlesBottom = articles.data.slice(3, articles.data.length);
    });
  },
};
</script>

<style lang="scss" scoped></style>
